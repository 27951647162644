import React from 'react';
import { navigateTo } from "gatsby-link";
import SEO from '../components/seo';
import Header from '../components/header'
import Hero from '../components/Hero'
import Layout from '../components/layout'
import Container from '../components/Container'
import Box from '../components/Box'
import FormLabel from '../components/FormLabel'
import InputText from '../components/InputText'
import Text from '../components/Text'
import Button from '../components/Button'
import Grid from '../components/Grid'
import '../css/form.css'

function encode(data) {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
        formData.append(key, data[key]);
    }

    return formData;
}

export default class Contact extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    handleChange = e => {
      this.setState({ [e.target.name]: e.target.value });
    };
  
    handleAttachment = e => {
      this.setState({ [e.target.name]: e.target.files[0] });
    };
  
    handleSubmit = e => {
      e.preventDefault();
      const form = e.target;
      fetch("/", {
        method: "POST",
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
        .then(() => navigateTo(form.getAttribute("action")))
        .catch(error => alert(error));
    };
  
    render() {
      return (
        <Layout>
            <SEO title='Talent Registration' /> 
            <Header />
            <Hero title="Startup Jobs Newsletter." subtitle="Start receiving emails with startup job opportunities that match your criteria." />
            <Container as="main" id='main-content'>
                <form
                    name="Newsletter"
                    method="post"
                    action="/success/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                > 
                    <InputText type="hidden" name="form-name" value="Newsletter" hidden/>
                    <Box as="fieldset" p="4" border="solid 2px #e5e5e5">
                        <Text as="legend" fontWeight="600">YOUR DETAILS</Text>
                        <Box mb="3">
                            <FormLabel htmlFor="first-name">First Name*</FormLabel>
                            <InputText
                                type="text"
                                onChange={this.handleChange}
                                name="firstname"
                                id="first-name"
                                required
                            />
                            <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please tell us your first name.</Text>
                        </Box>
                        <Box mb="3">
                            <FormLabel htmlFor="last-name">Last Name*</FormLabel>
                            <InputText
                                type="text"
                                onChange={this.handleChange}
                                name="lasttname"
                                id="last-name"
                                required
                            />
                            <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please tell us your last name.</Text>
                        </Box>
                        <Box mb="3">
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <InputText id="email" name="email" type="email" onChange={this.handleChange}/>
                            <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please provide your email address. This will remain private.</Text>
                        </Box>
                    </Box>

                    <Box as="fieldset" p="4" border="solid 2px #e5e5e5">
                        <Text as="legend" fontWeight="600">YOUR CRITERIA</Text>
                        <Box mb="4">
                          <FormLabel htmlFor="postition-type">Position Type</FormLabel>
                          <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please choose the position types that interest you. Choose as many as you like.</Text>
                          <Grid id="postition-type" gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr' ]} gridColumnGap={['0', '3', '3' ]} gridRowGap={['1', '1', '1' ]} mt="2">
                            <label class="CheckButton CheckButton--checkbox"><span>Full-time</span>
                            <input type="checkbox" name="Full-time" value="Full-time" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Part-time</span>
                            <input type="checkbox" name="Part-time" value="Part-time" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Freelance</span>
                            <input type="checkbox" name="Freelance" value="Freelance" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Volunteer</span>
                            <input type="checkbox" name="Volunteer" value="Volunteer" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                          </Grid>
                        </Box>
                        <Box mb="4">
                          <FormLabel htmlFor="experience-level">Experience Level</FormLabel>
                          <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please choose the experience levels that match your interests. Choose as many as you like.</Text>
                          <Grid id="experience-level" gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr' ]} gridColumnGap={['0', '3', '3' ]} gridRowGap={['1', '1', '1' ]} mt="2">
                            <label class="CheckButton CheckButton--checkbox"><span>Entry Level</span>
                            <input type="checkbox" name="Entry Level" value="Entry Level" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Junior</span>
                            <input type="checkbox" name="Junior" value="Junior" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Mid Level</span>
                            <input type="checkbox" name="Mid Level" value="Mid Level" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Senior Level</span>
                            <input type="checkbox" name="Senior Level" value="Senior Level" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Director</span>
                            <input type="checkbox" name="Director" value="Director" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Non-executive Director</span>
                            <input type="checkbox" name="Non-executive Director" value="Non-executive Director" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Executive</span>
                            <input type="checkbox" name="Executive" value="Executive" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                          </Grid>     
                        </Box>
                        <Box mb="4">
                          <FormLabel htmlFor="job-function">Job Function</FormLabel>
                          <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please choose the job functions that match your interests. Choose as many as you like.</Text>
                          <Grid id="job-function" gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr' ]} gridColumnGap={['0', '3', '3' ]} gridRowGap={['1', '1', '1' ]} mt="2">
                            <label class="CheckButton CheckButton--checkbox"><span>Administration</span>
                            <input type="checkbox" name="Administration" value="Administration" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Chairman</span>
                            <input type="checkbox" name="Chairman" value="Chairman" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Co-founder</span>
                            <input type="checkbox" name="Co-founder" value="Co-founder" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Customer Support</span>
                            <input type="checkbox" name="Customer Support" value="Customer Support" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Data Science</span>
                            <input type="checkbox" name="Data Science" value="Data Science" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Design</span>
                            <input type="checkbox" name="Design" value="Design" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Development</span>
                            <input type="checkbox" name="Development" value="Development" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Information Technology</span>
                            <input type="checkbox" name="Information Technology" value="Information Technology" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Intern</span>
                            <input type="checkbox" name="Intern" value="Intern" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Legal</span>
                            <input type="checkbox" name="Legal" value="Legal" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Management</span>
                            <input type="checkbox" name="Management" value="Management" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Manufacturing</span>
                            <input type="checkbox" name="Manufacturing" value="Manufacturing" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Marketing</span>
                            <input type="checkbox" name="Marketing" value="Marketing" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Non-executive Director</span>
                            <input type="checkbox" name="Non-executive Director" value="Non-executive Director" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>Sales</span>
                            <input type="checkbox" name="Sales" value="Sales" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                           
                          </Grid>     
                        </Box>
                        <Box mb="4">
                          <FormLabel htmlFor="company-size">Company size</FormLabel>
                          <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please choose the company sizes that match your interests. Choose as many as you like.</Text>
                          <Grid id="company-size" gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr' ]} gridColumnGap={['0', '3', '3' ]} gridRowGap={['1', '1', '1' ]} mt="2">
                            <label class="CheckButton CheckButton--checkbox"><span>0-5 Employees</span>
                            <input type="checkbox" name="0-5 Employees" value="0-5 Employees" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>5-10 Employees</span>
                            <input type="checkbox" name="5-10 Employees" value="5-10 Employees" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>10-25 Employees</span>
                            <input type="checkbox" name="10-25 Employees" value="10-25 Employees" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>25-50 Employees</span>
                            <input type="checkbox" name="25-50 Employees" value="25-50 Employees" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>50-100 Employees</span>
                            <input type="checkbox" name="50-100 Employees" value="50-100 Employees" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                            <label class="CheckButton CheckButton--checkbox"><span>100+ Employees</span>
                            <input type="checkbox" name="100+ Employees" value="100+ Employees" onChange={this.handleChange}/>
                              <div class="CheckButton-indicator"></div>
                            </label>
                          </Grid>                          
                        </Box>
                        <Box mb="4">
                            <FormLabel htmlFor="industry">Industry</FormLabel>
                            <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please select the industries that match your interests. Choose as many as you like.</Text>
                            <Grid id="industry" gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr' ]} gridColumnGap={['0', '3', '3' ]} gridRowGap={['1', '1', '1' ]} mt="2">
                              <label class="CheckButton CheckButton--checkbox"><span>Agriculture</span>
                              <input type="checkbox" name="Agriculture" value="Agriculture" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Business services</span>
                              <input type="checkbox" name="Business services" value="Business services" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Education &amp; Training</span>
                              <input type="checkbox" name="Education & Training" value="Education & Training" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Energy &amp; Natural Resources</span>
                              <input type="checkbox" name="Energy & Natural Resources" value="Energy & Natural Resources" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Entertainment &amp; Leisure</span>
                              <input type="checkbox" name="Entertainment & Leisure" value="Entertainment & Leisure" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Fashion &amp; Beauty</span>
                              <input type="checkbox" name="Fashion & Beauty" value="Fashion & Beauty" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Finance</span>
                              <input type="checkbox" name="Finance" value="Finance" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Food &amp; Beverage</span>
                              <input type="checkbox" name="Food & Beverage" value="Food & Beverage" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Hospitality, Restaurants &amp; Bars</span>
                              <input type="checkbox" name="Hospitality, Restaurants & Bars" value="Hospitality, Restaurants & Bars" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Manufacturing &amp; Engineering</span>
                              <input type="checkbox" name="Manufacturing & Engineering" value="Manufacturing & Engineering" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Media</span>
                              <input type="checkbox" name="Media" value="Media" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Medical &amp; Sciences</span>
                              <input type="checkbox" name="Medical & Sciences" value="Medical & Sciences" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Personal Services</span>
                              <input type="checkbox" name="Personal Services" value="Personal Services" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Products &amp; Inventions</span>
                              <input type="checkbox" name="Products & Inventions" value="Products & Inventions" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Property</span>
                              <input type="checkbox" name="Property" value="Property" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Retail</span>
                              <input type="checkbox" name="Retail" value="Retail" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Sales &amp; Marketing</span>
                              <input type="checkbox" name="Sales & Marketing" value="Sales & Marketing" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Software</span>
                              <input type="checkbox" name="Software" value="Software" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Technology</span>
                              <input type="checkbox" name="Technology" value="Technology" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                              <label class="CheckButton CheckButton--checkbox"><span>Transportation</span>
                              <input type="checkbox" name="Transportation" value="Transportation" onChange={this.handleChange}/>
                                <div class="CheckButton-indicator"></div>
                              </label>
                            </Grid>
                        </Box>
                        <Box mb="3">
                        <Box mb="2">
                          <FormLabel htmlFor="remote">Remote work</FormLabel>
                          <Text fontSize="1" color="light" py="1" lineHeight="1.3">Are you interested in working remotely?</Text>
                        </Box>
                          <fieldset id="remote">
                            <label className="control control--radio">Yes
                              <input type="radio" value="Yes" name="remote" onChange={this.handleChange}/>
                              <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio">No
                              <input type="radio" value="No" name="remote" onChange={this.handleChange}/>
                              <div className="control__indicator"></div>
                            </label>
                          </fieldset>
                      </Box>
                    </Box>
                    <Box my="3">
                      <Button variant="primary" id="submit-button" type="submit" minWidth="400px"><span role="img" aria-label="call me emoji">🤙</span> Join the list</Button>
                    </Box>
                </form> 
            </Container>
        </Layout>
        );
    }
}